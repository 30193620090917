<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo.png"
                  alt
                  height="50"
                  class="logo logo-dark"
                />
                <img
                  src="@/assets/images/logo.png"
                  alt
                  height="50"
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Register Account</h5>
                  <p class="text-muted">Join with your e-mail adress</p>
                </div>
                <div class="p-2 mt-4">
                  <form>
                    <div class="mb-3">
                      <label for="username">Name<strong>*</strong>:</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter name"
                        required
                        v-model="formData.name"
                        @keypress="clickKey('name')"
                        :class="{
                          'is-invalid': name,
                        }"
                      />
                      <div v-show="name" class="invalid-feedback">
                        <span>{{ name }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="username">Surname<strong>*</strong>:</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter surname"
                        required
                        v-model="formData.surname"
                        @keypress="clickKey('surname')"
                        :class="{
                          'is-invalid': surname,
                        }"
                      />
                      <div v-show="surname" class="invalid-feedback">
                        <span>{{ surname }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="useremail">Email<strong>*</strong>:</label>
                      <input
                        type="email"
                        class="form-control"
                        required
                        v-model="formData.email"
                        @keypress="clickKey('email')"
                        placeholder="Enter email"
                        :class="{
                          'is-invalid': email,
                        }"
                      />

                      <div v-show="email" class="invalid-feedback">
                        <span>{{ email }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="userpassword"
                        >Password<strong>*</strong>:</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        id="userpassword"
                        placeholder="Enter password"
                        required
                        v-model="formData.password"
                        @keypress="clickKey('password')"
                        :class="{
                          'is-invalid': password,
                        }"
                      />

                      <div v-show="password" class="invalid-feedback">
                        <span>{{ password }}</span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="userpassword"
                        >Re-type Password<strong>*</strong>:</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        id="userpassword"
                        placeholder="Enter re-type password"
                        required
                        v-model="formData.password_confirmation"
                        @keypress="clickKey('password_confirmation')"
                        :class="{
                          'is-invalid': password_confirmation,
                        }"
                      />

                      <div
                        v-show="password_confirmation"
                        class="invalid-feedback"
                      >
                        <span>{{ password_confirmation }}</span>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="userpassword"
                        >Date of Birth<strong>*</strong>:</label
                      >
                      <input
                        type="date"
                        class="form-control"
                        required
                        v-model="formData.date"
                        @change="clickKey('date')"
                        :class="{
                          'is-invalid': date,
                        }"
                      />

                      <div v-show="date" class="invalid-feedback">
                        <span>{{ date }}</span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label>Phone<strong>*</strong>:</label>
                      <input
                        type="tel"
                        class="form-control"
                        v-mask="'(###) ###-####'"
                        placeholder="(xxx) xxx-xxxx"
                        required
                        v-model="formData.phone"
                        @keypress="clickKey('phone')"
                        :class="{
                          'is-invalid': phone,
                        }"
                      />

                      <div v-show="phone" class="invalid-feedback">
                        <span>{{ phone }}</span>
                      </div>
                    </div>

                    <div class="mt-3 text-end">
                      <button
                        class="
                          btn btn-primary btn-block
                          w-sm
                          waves-effect waves-light
                        "
                        type="button"
                        @click="register()"
                      >
                        Register
                      </button>
                    </div>

                    <div class="mt-4 text-center">
                      <p class="text-muted mb-0">
                        Already have an account ?
                        <router-link to="/login" class="fw-medium text-primary"
                          >Login</router-link
                        >
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} Estetik International. Crafted
                with
                <i
                  class="mdi mdi-heart text-danger"
                  style="color: #b96955 !important"
                ></i>
                by Estetik International
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";

import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  data() {
    return {
      title: "Register",
      formData: {},
      name: "",
      surname: "",
      email: "",
      password: "",
      password_confirmation: "",
      date: "",
      phone: "",
      kvkk: "",
    };
  },
  methods: {
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async register() {
      var data;
      const response = await API.Post(
        API.Auth.Register,
        this.convertToFormData()
      );
      if (response.data.req) {
        this.validation(response);
        data = "Fill in the required fields";
        POPUP.popupClickNot(data);
      } else if (response.data.status === "success") {
        return this.$router.push("/login");
      }
    },
    clickKey(event) {
      if (event == "name") {
        var name = this.formData.name;
        if (name != null) {
          if (name.length > 2) {
            this.name = "";
          } else {
            this.name = "The name field is required.";
          }
        }
      }
      if (event == "surname") {
        var surname = this.formData.surname;
        if (surname != null) {
          if (surname.length > 2) {
            this.surname = "";
          } else {
            this.surname = "The surname field is required.";
          }
        }
      }
      if (event == "email") {
        var email = this.formData.email;
        if (email != null) {
          var sonuc = email.search("@");
          if (sonuc != -1) {
            this.email = "";
          } else {
            this.email = "The email field is required.";
          }
        }
      }
      if (event == "password") {
        var password = this.formData.password;
        if (password != null) {
          if (password.length > 4) {
            this.password = "";
          } else {
            this.password = "The password field is required.";
          }
        }
      }
      if (event == "password_confirmation") {
        var password_confirmation = this.formData.password_confirmation;

        if (password_confirmation != null) {
          if (
            password_confirmation.length > 4 &&
            this.formData.password_confirmation === this.formData.password
          ) {
            this.password_confirmation = "";
          } else {
            this.password_confirmation =
              "The password confirmation field is required.";
          }
        }
      }
      if (event == "date") {
        var date = this.formData.date;
        if (date != null) {
          if (date.length > 4) {
            this.date = "";
          } else {
            this.date = "The date field is required.";
          }
        }
      }
      if (event == "phone") {
        var phone = this.formData.phone;
        if (phone != null) {
          if (phone.length > 9) {
            this.phone = "";
          } else {
            this.phone = "The phone field is required.";
          }
        }
      }
      if (event == "kvkk") {
        var kvkk = this.formData.kvkk;
        if (!kvkk) {
          this.kvkk = "";
        } else {
          this.kvkk = "The kvkk field is required.";
        }
      }
    },
    validation(response) {
      if (response.data.status.name != null) {
        if (response.data.status.name[0] != null) {
          this.name = response.data.status.name[0];
        } else {
          this.name = "";
        }
      }

      if (response.data.status.surname != null) {
        if (response.data.status.surname[0] != null) {
          this.surname = response.data.status.surname[0];
        } else {
          this.surname = null;
        }
      }
      if (response.data.status.email != null) {
        if (response.data.status.email[0] != null) {
          this.email = response.data.status.email[0];
        } else {
          this.email = null;
        }
      }
      if (response.data.status.password != null) {
        if (response.data.status.password[0] != null) {
          this.password = response.data.status.password[0];
        } else {
          this.password = null;
        }
      }
      if (response.data.status.password_confirmation != null) {
        if (response.data.status.password_confirmation[0] != null) {
          this.password_confirmation =
            response.data.status.password_confirmation[0];
        } else {
          this.password_confirmation = null;
        }
      }
      if (response.data.status.date != null) {
        if (response.data.status.date[0] != null) {
          this.date = response.data.status.date[0];
        } else {
          this.date = null;
        }
      }
      if (response.data.status.phone != null) {
        if (response.data.status.phone[0] != null) {
          this.phone = response.data.status.phone[0];
        } else {
          this.phone = null;
        }
      }
      return true;
    },
  },
};
</script>

<style>
.authentication-bg {
  background-color: #f4f1f0 !important;
}
strong {
  color: rgba(239, 4, 4, 0.39);
}
</style>
